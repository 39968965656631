function tabInitials () {
  const prevButtons = document.getElementsByClassName("scroll-prev");
  const nextButtons = document.getElementsByClassName("scroll-next");

  setScrolling(prevButtons, nextButtons);
  setEditMode();
  autoFetch();
}

// #############################################################################
// Auto scrolling on boxes with buttons.
function setScrolling (prevButtons, nextButtons) {
  for (let i = 0; i < prevButtons.length; i++) {
    const type = prevButtons[i].getAttribute("data-type");
    const section = prevButtons[i].closest(".box-base").querySelector(`.${type}-section`);

    section.addEventListener("scroll", () => {
      if (section.scrollLeft === 0) {
        prevButtons[i].classList.add("btn-disabled");
      } else {
        prevButtons[i].classList.remove("btn-disabled");
      }

      if (section.scrollLeft + 10 > (section.scrollWidth - section.clientWidth)) {
        nextButtons[i].classList.add("btn-disabled");
      } else {
        nextButtons[i].classList.remove("btn-disabled");
      }
    });

    prevButtons[i].addEventListener("click", () => {
      const boxWidth = getBoxWidth(`${type}-box`);
      scrollContainer("prev", section, boxWidth);
    });
    nextButtons[i].addEventListener("click", () => {
      const boxWidth = getBoxWidth(`${type}-box`);
      scrollContainer("next", section, boxWidth);
    });
  }
}

function scrollContainer (toScroll, container, scrollWidth) {
  if (toScroll === "next") {
    container.scrollLeft = container.scrollLeft + scrollWidth;
  } else if (toScroll === "prev") {
    container.scrollLeft = container.scrollLeft - scrollWidth;
  }
}

function getBoxWidth (box) {
  return document.getElementsByClassName(box)[0].offsetWidth;
}

// #############################################################################
// Toggle edit mode.

function setEditMode () {
  const toggleEditModeSlider = document.getElementById("edit-mode-slider");
  if (toggleEditModeSlider) {
    toggleEditModeSlider.checked = localStorage.getItem("editMode") === "true";
    toggleEditModeSlider.addEventListener("click", toggleEditIcons);
  }
}

function toggleEditIcons () {
  const editModeToggle = document.getElementById("edit-mode-slider");
  const editIcons = document.querySelectorAll(".box-edit-icon");

  if (!editModeToggle) {
    return;
  }

  if (editModeToggle.checked) {
    editIcons.forEach((icon) => {
      icon.classList.remove("d-none");
    });
    localStorage.setItem("editMode", "true");
  } else {
    editIcons.forEach((icon) => {
      icon.classList.add("d-none");
    });
    localStorage.removeItem("editMode");
  }
}

// #############################################################################
// Auto HTTPRequest function and adding to HTML content.

// there are three must have, and two optional attributes.
// data-click-on-load="" <-- if this attribute is set to "true" when the content is loaded this element will be clicked.
// data-request-action-active="" <-- this attribute is when you need to add a active class to the specific buttons.
// data-request-target="" <-- this attribute is for the HTML element where the answered HTML needs to be stored.
// data-request-trigger <-- this element needs to be set for all elements who send an HTTPRequest.
// data-request-url="" <-- there you put the url that needs to be requested.
// data-request-relation="" <-- this for the Mutation observer to clean the related HTML elements. This needs to be set

// You can also set multiple urls and multiple HTML elements with the seperator "*.*".
// e.g.: data-request-target="div1*.*div2" data-request-url="url1*.*url2"
// So the content from url1 gets loaded in div1 and the content from url2 gets loaded in div2.

const customLoader = "<div class='d-flex justify-content-center align-items-center'><div class=\"loader\"></div></div>";

function autoFetch () {
  const SPLIT_SEPERATOR = "*.*";
  const triggers = document.querySelectorAll("[data-request-trigger]");

  for (const trigger of triggers) {
    let requestUrl = trigger.getAttribute("data-request-url");
    let elementsToSet = trigger.getAttribute("data-request-target");
    elementsToSet = elementsToSet.split(SPLIT_SEPERATOR);

    if ((requestUrl === SPLIT_SEPERATOR || requestUrl.length === 0)) {
      return;
    }

    requestUrl = requestUrl.split(SPLIT_SEPERATOR);

    if (!trigger.hasAttribute("has-http-request-listener")) {
      trigger.setAttribute("has-http-request-listener", "true");
      trigger.addEventListener("click", () => {
        for (let i = 0; i < elementsToSet.length; i++) {
          requestToElement(requestUrl[i], elementsToSet[i]);
        }

        const activeElements = document.querySelectorAll("[data-request-action-active]");
        for (let i = 0; i < activeElements.length; i++) {
          if (activeElements[i].getAttribute("data-request-action-active") === trigger.getAttribute("data-request-action-active")) {
            activeElements[i].classList.remove("active");
            trigger.classList.add("active");
          }
        }
      });

      if (trigger.nodeName === "DIV") {
        trigger.addEventListener("keypress", function (e) {
          if (e.key === "Enter") {
            trigger.click(); // Execute Click so the entry will be selected.
          }
        });
      }
    }

    if (trigger.getAttribute("data-click-on-load") === "true") {
      trigger.click();
      trigger.setAttribute("data-click-on-load", "false");
    }
  }
}

function requestToElement (url, elementClassToSet) {
  setContent(elementClassToSet, customLoader);
  fetch(url)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        setContent(elementClassToSet, "", true);
        throw new Error("Something went wrong. Please try again or come back later.");
      }
    })
    .then((html) => {
      if (html.includes('id="login-span-container"')) {
        window.location.reload();
        return null;
      }
      setContent(elementClassToSet, html);
      tabInitials();
    })
    .catch((errorMsg) => {
      setContent(elementClassToSet, errorMsg, true);
    });
}

function watchContainerChange (toWatch) {
  if (!toWatch.observers) {
    let resetNode = toWatch.getAttribute("data-request-relation");
    resetNode = document.querySelectorAll(`.${resetNode}`)[0];

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        resetNode.innerHTML = "";
      });
    });

    const config = {
      childList: true,
      subtree: true,
    };
    observer.observe(toWatch, config);
  }
}

function setContent (elementClassToSet, message, error = false) {
  const elements = document.querySelectorAll(`.${elementClassToSet}`);
  for (const element of elements) {
    if (element.hasAttribute("data-request-relation")) {
      watchContainerChange(element);
    }

    if (error && message) {
      element.innerHTML = message;
    } else if (error) {
      element.innerHTML = "Something went wrong. Please try again or come back later.";
    } else {
      element.innerHTML = message;
    }
  }
  toggleEditIcons();
}

tabInitials();

